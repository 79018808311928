import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from '../auth/authGuard'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      beforeEnter: authGuard,
      component: () => import('@/views/Dashboard.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
          },
        ],
      },
    },
    {
      path: '/apps',
      name: 'apps',
      component: () => import('@/views/Applications.vue'),
      meta: {
        pageTitle: 'My Apps',
        breadcrumb: [
          {
            text: 'My Apps',
            active: true,
          },
        ],
      },
    },
    {
      path: '/api',
      name: 'api',
      component: () => import('@/views/API.vue'),
      meta: {
        pageTitle: 'API',
        breadcrumb: [
          {
            text: 'API',
            active: true,
          },
        ],
      },
    },
    {
      path: '/sdk',
      name: 'sdk',
      component: () => import('@/views/SDK.vue'),
      meta: {
        pageTitle: 'SDK',
        breadcrumb: [
          {
            text: 'SDK',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
